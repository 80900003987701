import React, { useEffect, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { newProject, changeProject, archiveProject, findProjectIndex } from '../actions/ProjectListActions'
import { FiPlus, FiTrash2, FiDownload, FiUpload} from 'react-icons/fi'
import { MdEdit } from 'react-icons/md'
import { Dialog, DialogContent, Button, DialogActions, DialogTitle } from '@mui/material';
import ProjectSettings from './ProjectSettings';
import { checkToolbarWidth } from '../actions/ConfigActions';
import {fileOpen, fileSave} from 'browser-fs-access'
import ProjectImport from './ProjectImport';
import { updateName } from '../actions/ProjectActions';
import ProjectEditSettings from './ProjectEditSettings';


const MAX_PROJECTS_ALLOWED = 20
const MAX_PROJECTS_MSG = "Limit of " + MAX_PROJECTS_ALLOWED + " projects"
const DEFAULT_FILE_NAME = "projects.ganttbuster"

function ProjectListDialog(props) {
  const { onClose, open } = props;
  const projectList = useSelector(state => state.projectList.projects)
  const curProject = useSelector(state => state.project)
  const { timeline } = useSelector(p => p.project)
  const dispatch = useDispatch()
  const [editMode, setEditMode] = useState(false)
  const [newMode, setNewMode] = useState(false)
  const [importMode, setImportMode] = useState(false)
  const [importList, setImportList] = useState([])
  const buildSelected = () => {
    const list = {}
    projectList.forEach(p => list[p.id] = true)
    return list
  }

  const [exportList, setExportList] = useState(buildSelected)
  const canAdd = projectList.length < MAX_PROJECTS_ALLOWED

  const ANY = 0
  const DELETE = 1
  const EXPORT = 2
  const [buttonMode, setButtonMode] = useState(ANY)

  useEffect(() => {
      dispatch(checkToolbarWidth(timeline))
  }, [curProject.id])

  const handleClose = () => {
    onClose()
    setButtonMode(ANY)
    setImportMode(false)
    setEditMode(false)
  }

  const handleSettingsClose = () => {
    setEditMode(false)
  }

  const createNew = () => {
    setNewMode(true)
    setEditMode(true)
  }

  const handleNew = (name, type, start, end) => {
    if (canAdd) {
      dispatch(newProject(name, type, start, end))
      setEditMode(false)
      setNewMode(false)
      onClose()
    }
  }

  const handleUpdate = (name, type, start, end) => {
    if (canAdd) {

      dispatch(updateName)
      setEditMode(false)
      setNewMode(false)
      onClose()
    }
  }

  const handleEdit = (project) => {
    dispatch(changeProject(project))
    setNewMode(false)
    setEditMode(true)
  }

  const handleSelect = (project) => {
    dispatch(changeProject(project))
  }

  const handleDelete = (project) => {
    if (projectList.length > 1) {
      const index = findProjectIndex(projectList, project)

      if (project.id === curProject.id) {
        if (index > -1 && index < projectList.length - 1) {
          dispatch(changeProject(projectList[index + 1]))
        } else {
          dispatch(changeProject(projectList[0]))
        }
      }
    } else {
      dispatch(newProject())
    }

    dispatch(archiveProject(project))
  }

  function convertToFilename(string) {
    return string.replace(/[\/|\\:*?"<>]/g, " ") + ".ganttbuster"
  }

  const doImport = async () => {
    try {
        const blobs = await fileOpen([
            {
            description: 'Text files',
            mimeTypes: ['text/*'],
            extensions: ['.ganttbuster'],
            },
        ])

        const loadedProjects = JSON.parse(await blobs.text())
        setImportList(loadedProjects)
        setImportMode(true)
    } catch (e) {
        console.log(e)
    }
  }

  const toggleItem = (id, selected) => {
    setExportList(Object.assign({}, exportList, {[id]: selected}))
  }

  const exportProjects = async () => {
    const list = []
    projectList.forEach((p) => {
        if (exportList[p.id]) {list.push(p)}
    })

    const fileName = list.length === 1 ? convertToFilename(curProject.name) : DEFAULT_FILE_NAME
    try {
        const file = new Blob([JSON.stringify(list)], {type: 'text/plain'})
        await fileSave(file, {
            fileName: fileName,
            extensions: ['.ganttbuster'],
        });
    } catch (e) {
        console.log(e)
    }
    setButtonMode(ANY)
  }

  const isButtonMode = (mode = ANY) => {
    return buttonMode === mode
  }

  const toggleButton = (mode) => {
    buttonAvailable(mode) && setButtonMode(isButtonMode(mode) ? ANY : mode)
  }


  const buttonAvailable = (mode = ANY, disabled = false) => {
    return !disabled && (buttonMode === ANY || buttonMode === mode)
  }

  const buttonClassName = (mode = ANY, disabled = false) => {
    return buttonAvailable(mode, disabled) ? "button-enabled " : "button-disabled "
  }

  const projectItem = (project) => {
    return (
      <div className={project.id === curProject.id? "selected item" : "item"} key={project.id}>
        <div className='project-name' onClick={() => handleSelect(project)}>{project.name}</div>
        {isButtonMode(EXPORT) && <div className='project-delete'><input type="checkbox" defaultChecked={exportList[project.id]} onChange={(e) => toggleItem(project.id, e.target.checked)}></input></div>}
        {isButtonMode(DELETE) && <div className='project-delete' onClick={() => handleDelete(project)}><FiTrash2/></div>}
        {isButtonMode(ANY) && <div className='project-icon' onClick={() => {handleEdit(project)}}><MdEdit/></div>}
      </div>
    )
  }

  const buildProjectList = () => {
    let projectItems = []
    projectList.forEach(p => {
      projectItems.push(projectItem((p.id === curProject.id) ? curProject: p))
    })
    return projectItems
  }

  const projects = buildProjectList()
  const dialogTitle = newMode ? "Create a new project" : editMode ? curProject.name : importMode ? "Import projects" : "Projects"


  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="form-dialog-title">{ dialogTitle }</DialogTitle>

      <DialogContent>
      { ! editMode && ! importMode &&
        <div className='projects-toolbar'>
          <div className={buttonClassName(ANY, !canAdd)} onClick={() => {buttonAvailable(ANY, !canAdd) && createNew()}}><FiPlus title={(canAdd?'New project':MAX_PROJECTS_MSG)}/></div>
          <div onClick={() => toggleButton(DELETE)} className={buttonClassName(DELETE) + (isButtonMode(DELETE)?'delete-button-selected':'')}><FiTrash2 title="Toggle delete"/></div>
          <div></div>
          <div onClick={() => buttonMode === ANY && doImport()} className={buttonClassName(ANY)}><FiUpload title="Import"/></div>
          <div onClick={() => toggleButton(EXPORT)} className={buttonClassName(EXPORT) + (isButtonMode(EXPORT)?'button-selected':'')}><FiDownload title="Toggle export"/></div>
        </div>
      }
      { ! editMode && ! importMode &&
        <div className='projects'>
          {projects}
        </div>
        }

      { editMode && newMode && ! importMode &&
        <ProjectSettings handleClose={ handleSettingsClose } handleNew={ handleNew } isNew={newMode} />
      }

      { !newMode && editMode && ! importMode &&
        <ProjectEditSettings handleClose={ handleSettingsClose } open={ true }/>
      }

      { importMode &&  ! editMode &&
      <ProjectImport onClose={ () => setImportMode(false)} importList={importList} />
      }

      </DialogContent>
      <DialogActions>
        {isButtonMode(EXPORT) && !editMode && <Button onClick={exportProjects} disabled={Object.keys(exportList).filter(k => exportList[k]).length < 1}>Export selected</Button>}
      </DialogActions>
    </Dialog>
  );
}


export default ProjectListDialog