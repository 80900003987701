export const ACCEPT_TERMS = 'ACCEPT_TERMS'
export const SET_ACCOUNT = 'SET_ACCOUNT'
export const SET_MODE = 'SET_MODE'

export function acceptTerms() {
    return {
        type: ACCEPT_TERMS
    }    
}

export function setAccount(userId, token) {
    return {
        type: SET_ACCOUNT,
        token: token,
        userId: userId,
    }    
}

export function setMode(mode) {
    return {
        type: SET_MODE,
        mode: mode,
    }    
}

export function login(accessToken) {
    return async (dispatch, getState) => {
        const state = getState()
        const apiDomain = state.config.apiDomain
        const config = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',            
            },
            body: JSON.stringify({token: accessToken})
        };

        console.log(config)
        try {
            const fetchResponse = await fetch(`${apiDomain}/register`, config)
            const data = await fetchResponse.json()
            console.log(data)
            dispatch(setAccount(data.id, data.token))
            return data;
        } catch (e) {
            return e;
        }    
    }
}
