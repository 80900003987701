import { ADD_PROJECT, UPDATE_PROJECT, ARCHIVE_PROJECT } from '../actions/ProjectListActions'
import { UPGRADE } from '../actions/ConfigActions'
import { LATEST_VERSION } from '../Version'
import upgrade from './Upgrader'
import { cloneProject } from './ProjectReducer'
import { upgradeProjects } from './ProjectUpgrader'

const ARCHIVE_MAX = 10

const initialState = {
  version: LATEST_VERSION,
  projects: [],
  archive: [],
  tokens: {},
  index: 0,
}


const upgrades = [
    {
      version: LATEST_VERSION,
      state: {},
    }
]


export const projectList = function (state = initialState, action) {
    switch (action.type) {
      case UPGRADE: {
        if (!state.version || (LATEST_VERSION > state.version)) {
          const interiumState = upgrade(state, upgrades, action.config)
          return {
            ...interiumState,
            projects: upgradeProjects(interiumState.projects, LATEST_VERSION, action.config)
          }
        }
        return state
      }

      case ADD_PROJECT: {
        if (projectList.length > 9) {
          return state
        }

        const project = cloneProject(action.project)
        let tokens = Object.assign({}, state.tokens)
        if (action.token) {
          tokens[action.token] = action.project.id
        }
        return {
          ...state,
          projects: [...state.projects, project],
          tokens: tokens,
        }
      }

      case UPDATE_PROJECT: {
        const project = cloneProject(action.project)
        const index = findProjectIndex(state.projects, project)
        if (index > -1) {
          const newList = [
            ...state.projects.slice(0, index),
            project,
            ...state.projects.slice(index + 1)
          ]
          return {
            ...state,
            projects: newList,
          }
        } else {
          return {
            ...state,
            projects: [...state.projects, project],
          }
        }
      }

      case ARCHIVE_PROJECT: {
        const index = findProjectIndex(state.projects, action.project)
        if (index > -1) {
          const newList = [
            ...state.projects.slice(0, index),
            ...state.projects.slice(index + 1)
          ]
          return {
            ...state,
            projects: newList,
            archive: [
              action.project,
              ...state.archive.slice(0, state.archive.length > ARCHIVE_MAX ? ARCHIVE_MAX : state.archive.length)
            ]
          }
        } else {
          return state
        }
      }

      default:
        return state;
    }
  }

  function findProjectIndex(projectList, project) {
    for (let i = 0; i < projectList.length; i++) {
      if (projectList[i].id === project.id) {
        return i;
      }
    }
    return -1;
  }


