import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from './configureStore';
import Cookies from './components/Cookies';

const {store, persistor} = configureStore()

const MyApp = () => (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
        <Cookies hasAccepted={() => persistor.persist()}/>
      </PersistGate>
    </Provider>
  );

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<MyApp />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
