import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../App.css';
import Label from './Label';
import { updateName } from '../actions/ProjectActions'

const ProjectName = (props) => {
  const dispatch = useDispatch()
  const name = useSelector(state => state.project.name)

  return (
    <div className='title-bg'><Label value={name} onSave={(value) => dispatch(updateName(value))} className='title'/></div>
  )
}

export default ProjectName;
