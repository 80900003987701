import { EDIT_TASK, EDIT_DONE } from '../actions/ProjectActions'
import {
  UPDATE_MODE,
  UPGRADE,
  CHECK_TOOLBAR_WIDTH,
  PROJECT,
  MOBILE_DEFAULTS,
  SWIMLANES,
  SET_CONFIG,
} from '../actions/ConfigActions'
import upgrade from './Upgrader'
import { LATEST_VERSION } from '../Version'


const initialState = {
  version: LATEST_VERSION,
  editTask: {},
  toolbarWidth: 0,
  runningChecks: false,
}

const upgrades = [
  {
    version: 5,
    state: {
      runningChecks: false,
    }
  },
  {
    version: LATEST_VERSION,
    state: {}
  }
]

const mobile = {
  columns: 3,
  displayMode: SWIMLANES,
  incrementWidth: 15,
  //columnWidth: 15 * trackSettings.increments,
  columnWidth: 15 * 16,
}

const configReducer = function (state = initialState, action) {
  switch (action.type) {
    case EDIT_TASK: {
      return {
        ...state,
        edit: true
      }
    }
    case EDIT_DONE: {
      return {
        ...state,
        editInline: false,
        edit: false,
      }
    }
    case UPDATE_MODE: {
      return {
        ...state,
        editMode: action.mode
      }
    }
    case UPGRADE: {
      if (initialState.version > state.version) {
        return upgrade(state, upgrades)
      }
      return state
    }
    case CHECK_TOOLBAR_WIDTH: {
      return {
        ...state,
        toolbarWidth: calculateToolbarWidth(action.timeline.columns, action.timeline.displayMode, action.timeline.columnWidth, action.timeline.labelColWidth)
      }
    }
    case MOBILE_DEFAULTS: {
      const adjWidth = Math.floor((action.width - 20) / (3 * 8))
      const s = {
        ...state,
        columns: mobile.columns,
        displayMode: mobile.displayMode,
        columnWidth: adjWidth * state.increments,
        incrementWidth: adjWidth,
        windowLength: mobile.columns * state.increments,
        //toolbarWidth: calculateToolbarWidth(mobile.columns, mobile.displayMode, adjWidth * state.increments, trackSettings.labelColumnWidth)
      }
      return s
    }
    case SET_CONFIG: {
      return {
        ...state,
        apiDomain: action.config.apiDomain,
        origin: action.config.origin,
      }
    }
    default:
      return state
  }
}

function calculateToolbarWidth(columns, displayMode, colWidth, labelColWidth) {
  return columns * colWidth + (displayMode === PROJECT ? labelColWidth : 0)
}

export { configReducer }