import React, { useEffect } from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { v4 as uuidv4 } from 'uuid';
import '../App.css'
import { FiPlus, FiChevronLeft, FiChevronRight, FiList, FiMenu, FiSettings, FiSave, FiUser } from 'react-icons/fi'
import { IoAlbumsOutline } from "react-icons/io5"
import { MdEdit, MdDelete } from 'react-icons/md'
import { updateMode, checkToolbarWidth } from '../actions/ConfigActions'
import { trackLeft, trackRight, updateDisplayMode, addTask, saveProject } from '../actions/ProjectActions'
import styled from 'styled-components'

const Toolbar = ({showSettings, showLogin, showProjects, large}) => {
  const { editMode, toolbarWidth } = useSelector(s => s.config)
  const { timeline } = useSelector(s => s.project)
  const project = useSelector(s => s.project)
  const { mode, acceptedTerms } = useSelector(s => s.account)
  const dispatch = useDispatch()
  const show_login = mode.isAlpha

  useEffect(() => {
    if (toolbarWidth === 0) {
      dispatch(checkToolbarWidth(timeline))
    }
  }, [])

  function addRow() {
    dispatch(addTask({id: uuidv4(), row: project.numRows, name: '', type: 'task', start: timeline.index, length: timeline.defaultTaskLength, colour:'#3771f3', borderColour:'#2558cc'}))
  }

  function doGA(mode) {}

  const iconClass = large ? 'toolbar-icon-large' : 'toolbar-icon'
  return (
    <div className='toolbar-bg'>
        <Tbar className="toolbar" $width={toolbarWidth} $large={large} onMouseLeave={() => dispatch(checkToolbarWidth(timeline))}>
          <IoAlbumsOutline
            className={iconClass}
            color={acceptedTerms ? 'white' : '#666666'}
            size={large?'2em':'1em'}
            onClick={() => {if (acceptedTerms) showProjects() }}
            title="Project list"
          />
          <FiPlus
            className={iconClass}
            color='white'
            size={large?'2em':'1em'}
            onClick={() => {addRow(); doGA('add')} }
            title="Add row"
          />
          <MdEdit
            className={iconClass}
            color={editMode === 'EDIT'?'yellow':'white'}
            size={large?'2em':'1em'}
            onClick={() => {dispatch(updateMode(editMode !== 'EDIT'?'EDIT':undefined)); doGA('edit')}}
            title="Show edit icons"
          />
          <MdDelete
            className={iconClass}
            color={editMode === 'DELETE'?'yellow':'white'}
            size={large?'2em':'1em'}
            onClick={() => {dispatch(updateMode(editMode !== 'DELETE'?'DELETE':undefined)); doGA('delete')}}
            title="Show delete icons"
          />
          {timeline.displayMode !== 'SWIMLANES' &&
            <FiList
              className={iconClass}
              color={'white'}
              size={large?'2em':'1em'}
              onClick={() => {dispatch(updateDisplayMode('SWIMLANES')); doGA('display')}}
              title="Toggle swimlanes"
            />
          }
          {timeline.displayMode === 'SWIMLANES' &&
            <FiMenu
              className={iconClass}
              color={'white'}
              size={large?'2em':'1em'}
              onClick={() => {dispatch(updateDisplayMode('PROJECT')); doGA('display')}}
              title="Toggle project style"
            />
          }
          <FiChevronLeft
            className={iconClass}
            color='white'
            size={large?'2em':'1em'}
            onClick={() => {dispatch(trackLeft()); doGA('left')}}
            title="Scroll left"
          />
          <FiChevronRight
            className={iconClass}
            color='white'
            size={large?'2em':'1em'}
            onClick={() => {dispatch(trackRight()); doGA('right')}}
            title="Scroll right"
          />
          <div className='toolbar-right'>
          { show_login &&
            <FiUser
              className={iconClass}
              color='#666666'
              size={large?'2em':'1em'}
              onClick={() => showLogin()}
              title=""
            />
          }
          { show_login &&
            <FiSave
              className={iconClass}
              color='#666666'
              size={large?'2em':'1em'}
              onClick={() => dispatch(saveProject(project))}
              title=""
            />
          }
            <FiSettings
              className={iconClass}
              color='#666666'
              size={large?'2em':'1em'}
              onClick={() => showSettings()}
              title="Project settings"
            />
          </div>
        </Tbar>
      </div>
  )
};

const Tbar = styled.div`
  width: ${p => p.$large ? '100%' : p.$width + 'px'};
  padding: ${p => p.$large ? '6px 0 6px 0': '5px'};
`

export default Toolbar;
