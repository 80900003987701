import React from 'react';
import { useSelector } from 'react-redux';
import { WEEKLY } from '../actions/ProjectActions';
import '../App.css';
import TrackHeading from './TrackHeading';

const TrackHeadings = () => {
  const { editMode } = useSelector(p => p.config)
  const { track, index, increments, columns, justifyLeft } = useSelector(p => p.project.timeline)
  const { type } = useSelector(p => p.project)
  let maxColumns = (columns * increments)
  if (maxColumns > track.length) {
    maxColumns = track.length
  }

  maxColumns += (editMode ? 2 : 1)
  const barStartCol = editMode ? 3 : 2
  const positionHasTopLevelHeading = (index) => track[index] && track[index].some(h => h.row === 0)
  const headingRow = 1

  function buildheadingTracks() {
    let ganttHeadings = []
    let colIndex = [barStartCol, barStartCol]

    /*
     * If the index is not at the start of the track and the current position does not have a top level heading
     * then iterate backwards from the index to find the previous top level heading
     */
    if (index > 0 && !positionHasTopLevelHeading(index)) {
      for (let i = index - 1; i >= 0; i--) {
        if (positionHasTopLevelHeading(i)) {
          let h = track[i].find(h => h.row === 0)
          let col2 = i - index + h.length + barStartCol       // Truncate start of heading
          if (col2 > (maxColumns + 1)) col2 = maxColumns + 1  // If still too long truncate end
          ganttHeadings.push(
            <TrackHeading key={h.label + colIndex[0]} col1={colIndex[0]} col2={col2} row={headingRow + h.row} label={h.label} />)
            colIndex[0] = col2
          break
        }
      }
    }

    /*
     * For each track position add the headings at that position
     */
    for (let i = index; i < track.length; i++) {
      const headings = track[i]
      if (headings.length > 0) {
        for (const h of headings) {
          if (h.row > 1) continue;
          const col2 = (colIndex[h.row] + h.length) <= (maxColumns + 1) ? colIndex[h.row] + h.length : maxColumns + 1
          const key = (headingRow + h.row) + '_' + colIndex[h.row]
          let className = (h.row === 1 && justifyLeft) ? 'time2 left-time' : 'time2'
          ganttHeadings.push(
            <TrackHeading key={key} col1={colIndex[h.row]} col2={col2} row={headingRow + h.row} label={localiseLabel(h)} className={className} />)
          colIndex[h.row] = col2
        }

      }

      if (colIndex[0] > maxColumns && colIndex[1] > maxColumns) break;
    }

    return ganttHeadings;
  }

  function localiseLabel(heading) {
    return (type === WEEKLY && heading.row == 1) ? new Date(heading.value).toLocaleDateString() : heading.name;
  }

  return (
    <>
        <div style={{ gridColumn: "1 / 2", gridRow: "1 / 2"}} className='time1'></div>
        {editMode && <div style={{ gridColumn: "2 / 3", gridRow: "1 / 2"}} className='time1'></div>}
        <div style={{ gridColumn: "1 / 2", gridRow: "2 / 3"}} className='row-spacer'></div>
        {editMode && <div style={{ gridColumn: "2 / 3", gridRow: "2 / 3"}} className='time1'></div>}
        { buildheadingTracks(1, index, 'time2', track)}
        <div style={{ gridColumn: "1 / 2", gridRow: "3 / 4"}} className='row-spacer'></div>
        <div style={{ gridColumn: "2 / 3", gridRow: "3 / 4"}} className='row-spacer'></div>
    </>
  )
}

export default TrackHeadings;
