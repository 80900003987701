import React, { useState, useEffect } from 'react';
import SocialLogin from 'react-social-login'
 
function SocialButton(props) {
    const { triggerLogin, children} = props
    const [googleLoaded, setGoogleLoaded] = useState(false)
    let retries = 60

    async function checkGoogleLoaded() {
      if (!googleLoaded) {
        if (window.gapi === undefined) {
          if (retries-- > 0) {            
            setTimeout(() => checkGoogleLoaded(), 500)                     
          } else {
            console.log("gave up waiting for google login.")
          }
        } else {
          setGoogleLoaded(true)
        }
      }  
    }
    
    useEffect(() => {
      checkGoogleLoaded(); 
    },[])
   
    if (googleLoaded) {
      return (
          <button onClick={() => triggerLogin()} >
            { children }
          </button>
      ) 
    } else {
      return (
        <button onClick={() => triggerLogin()} disabled >
          { children }
        </button>
      )
    }
}

export default SocialLogin(SocialButton);