import React, { useState } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { importProjects } from '../actions/ProjectListActions'
import { Button, DialogActions } from '@mui/material';
import { LATEST_VERSION } from '../Version';


function ProjectImport(props) {
  const { onClose, importList } = props;
  const projectList = useSelector(state => state.projectList.projects)
  const dispatch = useDispatch()
  const buildSelectedList = () => {
    const list = {}
    projectList.forEach(p => list[p.id] = true)
    importList.forEach(p => list[p.id] = true)
    return list
  }

  const [selectedList, setSelectedList] = useState(buildSelectedList())

  const handleClose = () => {
    onClose()
  }

  const toggleItem = (id, selected) => {
    setSelectedList(Object.assign({}, selectedList, {[id]: selected}))
  }

  const doImport = async () => {
    const list = []
    importList.forEach((p) => {
        if (selectedList[p.id]) {list.push(p)}
    })
    if (list.length > 0) {
        dispatch(importProjects(list))
    }
    onClose()
  }

  const projectExists = (project) => {
    return projectList.findIndex(p => p.id === project.id)
  }

  const millisToStr = (time) => {
    return time != undefined ? new Date(time).toLocaleDateString() + " " + new Date(time).toLocaleTimeString() : ""
  }

  const isMoreRecent = (project, index) => {
    return !project.lastUpdated || project.lastUpdated < projectList[index].lastUpdated
}


  const projectItem = (project) => {
    const index = projectExists(project)
    return (
      <div className="item" key={project.id}>
        <div className='project-name'>
            <div title={millisToStr(project.lastUpdated)}>{project.name} { index > -1 &&  <span className='project-info timestamp'>{millisToStr(project.lastUpdated)}</span>}</div>
            { (project.version < LATEST_VERSION) && <div className='project-info'>Will be upgraded: version {project.version} → {LATEST_VERSION}</div>}
            { index > -1 &&
            <div className='project-info'>Will overwrite <span className='overwrite-name'>{projectList[index].name}</span> <span className={"project-info timestamp " + (isMoreRecent(project, index)?"earlier":"") }>{millisToStr(projectList[index].lastUpdated)}</span>
            </div>}
        </div>
        <div className='project-delete'><input type="checkbox" defaultChecked={selectedList[project.id]} onChange={(e) => toggleItem(project.id, e.target.checked)}></input></div>
      </div>
    )
  }

  const buildProjectList = () => {
    let projectItems = []
    importList.forEach(p => {
      projectItems.push(projectItem(p))
    })
    return projectItems
  }

  const projects = buildProjectList()


  return (
    <div>
        <div className='projects projects-import'>
          {projects}
        </div>
        <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={doImport} disabled={selectedList.length < 1}>Import</Button>
        </DialogActions>
    </div>
  );
}


export default ProjectImport
