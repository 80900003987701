import React from 'react';
import { useDispatch } from 'react-redux'
import { updateTask } from '../actions/ProjectActions';
import Label from './Label'

const TaskLabel = (props) => {
  const {task, className='name'} = props;
  const dispatch = useDispatch()

  const saveTask = (name) => {
    dispatch(updateTask(task.id, {name: name}))  
  }
  
  return (
    <Label value={task.name} onSave={saveTask} className={className}/>
  )  
}

export default TaskLabel;
