/**
 * Weekly Track
 * row[0] is month
 * row[1] is week
 * row[2] is ?
 * row[3] is day (date)
 */

import { isoDateFormat } from "./reducers/utils";

const MONTHS = ["January","February","March","April","May","June","July","August","September","October","November","December"];

export function buildTrack(startDate=new Date(), givenLength=365) {
    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
    var gridTrack = []
    var day = startDate

    //const DAYS = ["S", "M", "T", "W", "T", "F", "S"]
    var start = startDate

    /* Wind back to the start of the current week */
    let length = givenLength
    while (start.getDay() !== 1) {
        start.setDate(start.getDate() - 1)
        length++
    }

    const endOfWeekGap = 6 - (length % 7)
    length += endOfWeekGap

    for (let index = 0; index <= length; index++) {
        let slot = []
        if (startOfMonth(day)) {
            let endOfMonth = new Date(day.getFullYear(), day.getMonth() + 1, 0)
            const monthLength = (index + endOfMonth.getDate() > length) ? length - index + 1 : endOfMonth.getDate()
            slot.push({label: MONTHS[day.getMonth()], name: MONTHS[day.getMonth()], length: monthLength, row: 0})
        } else if (index === 0) { /* Truncate first month */
            let endOfMonth = new Date(start.getFullYear(), start.getMonth() + 1, 0)
            slot.push({label: MONTHS[day.getMonth()], name: MONTHS[day.getMonth()], length: endOfMonth.getDate() - start.getDate() + 1, row: 0})
        }

        const isoDate = isoDateFormat(day)
        if (startOfWeek(day)) {
            slot.push({label: isoDate, name: 'week ' + (index + 1), length: 7, row: 1, value: isoDate})
        }

        slot.push({label: isoDate, name: 'day ' + day.getDay(), length: 1, row: 3, value: isoDateFormat(day)})

        gridTrack.push(slot)
        day.setDate(day.getDate() + 1)
    }

    return gridTrack
}

const WEEKLY_TRACK = {
    columns: 6,
    labelColumnWidth: 220,
    increments: 7,
    incrementWidth: 16,
    windowLength: 42,
    trackLength: 365,
    index: 0,
    justifyLeft: true,
    displayMode: 'PROJECT',
    defaultTaskLength: 7,
}

function startOfMonth(day) {
    return day.getDate() === 1;
}

function startOfWeek(day) {
    return day.getDay() % 7 === 1;
}

export function getTrackSettings() { return WEEKLY_TRACK }


export function dateToTrackPosition(curDate, curTrackPos, newDate) {

}

export function trackPositionToDate(curDate, curTrackPos, newTrackPos) {

}
