import React, { useState, useEffect } from 'react'
import Select from '@mui/material/Select'
import { Button, DialogActions, FormControl, InputLabel, TextField } from '@mui/material'
import { SOFTENED, ROADMAP, ROUNDED, SHARP, updateName, updateStrictGantt, updateStyle, WEEKLY, updateColumns, updateLabelColWidth } from '../actions/ProjectActions'
import { isoDateFormat } from '../reducers/utils'
import {useSelector, useDispatch} from 'react-redux'
import { checkToolbarWidth } from '../actions/ConfigActions';
import { isMobile, isTablet } from 'react-device-detect'
import Typography from '@mui/material/Typography';


function ProjectEditSettings({ handleClose, open}) {
  const dispatch = useDispatch()
  const project = useSelector(state => state.project)
  const { timeline } = useSelector(p => p.project)

  const isNew = false
  const [descError, setDescError] = useState("")
  const [endDateError, setEndDateError] = useState("")
  const canSupportStrict = ! hasMultiTaskRows()

  useEffect(() => {
    if (open) {
      dispatch(checkToolbarWidth(timeline))
    }
  }, [timeline])

  function onNameChange(name) {
    const hasError = validate(name)
    if (!hasError) {
        dispatch(updateName(name))
    }
  }

  function onTypeChange(value) {
    // noop
  }

  function onStrictGanttChange(checked) {
    dispatch(updateStrictGantt(!checked))
  }

  function onStyleChange(value) {
    dispatch(updateStyle(value))
  }

  function validate(name) {
    let hasError = false

    if (!name || name.length < 1) {
        hasError = true
        setDescError("Projects need a name")
    } else {
        setDescError("")
    }

    return hasError
  }

  function hasMultiTaskRows() {
    for ( let i = 0; i < project.numRows; i++) {
        const row = project.tasks.filter(t => t.row == i)
        if (row.length > 1) {
            return true
        }
    }
    return false
  }

  function onColumnChange(value) {
    const columns = parseInt(value)
    dispatch(updateColumns(columns))
  }

  function onLabelColChange(value) {
    const width = parseInt(value)
    dispatch(updateLabelColWidth(width))
  }

  const columnOptions = () => {
    const row = [];
    const maxCols = (timeline.maxColumns < 12) ? timeline.maxColumns : 12
    for (var i = 1; i <= maxCols; i++) {
      row.push(<option key={"col" + i} value={i}>{i}</option>);
    }
    return row;
  }

  return (
        <div className='settings project-settings'>
          <div>
            <TextField id="outlined-basic" variant="standard" label="Project name" onChange={ (e) => onNameChange(e.target.value) } defaultValue={ project.name } autoFocus error={(descError != undefined && descError.length > 0)} helperText={descError} />
          </div>
          <div>
            <FormControl fullWidth variant="standard">
              <InputLabel id="id1" className="label-disabled">Project type</InputLabel>
              <Select native defaultValue={ project.type } onChange={ (e) => onTypeChange(e.target.value) } labelId="id1" data-testid="project-t" disabled={!isNew}>
                <option key='col2' value={ ROADMAP }>Quarterly Roadmap (Quarters/months)</option>
                <option key='col3' value={ WEEKLY }>Weekly Project Plan (Date based weeks)</option>
              </Select>
            </FormControl>
              { project.type === WEEKLY &&
              <div>
                <p className="label label-disabled">Project start week</p>
                <input type='date' defaultValue={isoDateFormat(new Date(project.startDate))} onChange={(e) => {}}  disabled={!isNew}></input>
              </div>
              }
              {project.type === WEEKLY && endDateError && <p className="error-msg">{endDateError}</p>}
              { project.type === WEEKLY &&
              <div className='bottom-row'>
                <p className="label label-disabled">Project end week</p>
                <input type='date' defaultValue={isoDateFormat(new Date(project.endDate))} onChange={(e) => {}} data-testid="end-date" disabled={!isNew}></input>
              </div>
              }
              <div className="bottom-row">
                <span className={"label" + (!canSupportStrict ? " label-disabled" : "")}>Allow multiple tasks</span>
                <input type="checkbox" defaultChecked={!project.strictGantt} onChange={(e) => onStrictGanttChange(e.target.checked)} disabled={!canSupportStrict} title={!canSupportStrict?"Project has multiple tasks on a row.":""}></input>
              </div>
              <FormControl fullWidth variant="standard" className='bottom-row'>
                <InputLabel id="task-style" className="label-disabled">Task bar styling</InputLabel>
                <Select native defaultValue={ project.style } onChange={ (e) => onStyleChange(e.target.value) } labelId="task-style" data-testid="project-s">
                    <option key='rounded' value={ ROUNDED }>Rounded</option>
                    <option key='regular' value={ SOFTENED }>Softened</option>
                    <option key='sharp' value={ SHARP }>Sharp</option>
                </Select>
              </FormControl>
              { (!isMobile || isTablet) &&
                <>
                <div>
                    <Typography className='MuiInputLabel-shrink setting-label' color="textSecondary">Number of columns</Typography>
                    <Select native variant="standard" value={ timeline.columns } onChange={ (e) => onColumnChange(e.target.value) }>
                        { columnOptions() }
                    </Select>
                </div>
                <div>
                    <Typography className='setting-label' color="textSecondary">Label column width</Typography>
                    <Select native variant="standard" value={ timeline.labelColWidth } onChange={ (e) => onLabelColChange(e.target.value) }>
                    <option key='col2' value='200'>200</option>
                    <option key='col3' value='220'>220</option>
                    <option key='col4' value='240'>240</option>
                    <option key='col5' value='260'>260</option>
                    <option key='col6' value='280'>280</option>
                    <option key='col7' value='300'>300</option>
                    </Select>
                </div>
                </>
            }
            { handleClose !== undefined &&
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
            }
          </div>
        </div>
  );
}

export default ProjectEditSettings