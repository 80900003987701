import { LATEST_VERSION } from '../Version'
import { setMode } from './AccountActions'
import { loadProject } from './ProjectListActions'

export const UPDATE_MODE = 'UPDATE_MODE'
export const UPGRADE = 'UPGRADE'
export const CHECK_TOOLBAR_WIDTH = 'CHECK_TOOLBAR_WIDTH'
export const PROJECT = 'PROJECT'
export const SWIMLANES = 'SWIMLANES'
export const MOBILE_DEFAULTS = 'MOBILE_DEFAULTS'
export const SET_CONFIG = 'SET_CONFIG'

export function updateMode(mode) {
    return {
        type: UPDATE_MODE,
        mode: mode
    }
}

export function upgrade(config) {
    return {
        type: UPGRADE,
        config: config
    }
}

export function checkToolbarWidth(timeline) {
    return {
        type: CHECK_TOOLBAR_WIDTH,
        timeline: timeline
    }
}

export function mobileDefaults(width) {
    return {
        type: MOBILE_DEFAULTS,
        width: width
    }
}

export function setConfig(config) {
    return {
        type: SET_CONFIG,
        config: config
    }
}


export function checkUpgrade() {
    return async (dispatch, getState) => {
        const state = getState()
        const version = state.config.version

        if (LATEST_VERSION > version) {
            console.log('upgrading')
            dispatch(upgrade(state.config))
        }
    }
}

export function checkDeepLink() {
    return async (dispatch, getState) => {
        const state = getState()
        const tokens = state.projectList.tokens

        const pathSegments = window.location.pathname.split("/")
        if (pathSegments && pathSegments.length > 2 && [pathSegments[1] === "rm"]) {
            const token = pathSegments[2]
            console.log(token)
            if (!(token in tokens)) {
                dispatch(setMode('alpha'))
                dispatch(loadProject(token))
            }
        }
    }
}