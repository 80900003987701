import React, { useState, useEffect } from 'react';
import '../App.css';
import { Rnd } from "react-rnd";
import styled from 'styled-components'
import { debounce } from "debounce"
import { CIRCLE, DIAMOND, SOFTENED, ROUNDED, SHARP } from '../actions/ProjectActions';


const resizing = {
  bottom: false,
  bottomLeft: false,
  bottomRight: false,
  left: false,
  right: false,
  top: false,
  topLeft: false,
  topRight: false
}

const RADIUS = {
    ROUNDED: 2,
    SOFTENED: 1,
    SHARP: 0,
}

const Milestone = ({
  start,
  width,
  updatePosition,
  colour='#3771f3',
  borderColour='#2558cc',
  label='',
  onClick,
  gridWidth,
  shape=CIRCLE,
  barStyle=ROUNDED,
}) => {
  const [isAdjusting, setIsAdjusting] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [position, setPosition] = useState({x: start, y: 0})

  useEffect(() => {
    setPosition({x: start, y: 0})
  }, [start])

  function handleOnClick() {
    if (!isAdjusting) {
      onClick()
    }
    setIsAdjusting(false)
  }

  const onDrag = (e, data) => {
    setIsAdjusting(true);
    setIsDragging(true);
    setPosition({x: data.x, y: data.y})
  }

  /* debounce to avoid updating state issue in react-draggable */
  const move= debounce((e, data) => updatePosition(data.x, data.y), 0)

  return (
    <StyledBar
      $zindex={isAdjusting ? 'z-index: 10;' : ''}
      $opacity={isDragging ? '0.9' : '1.0'}
      enableResizing={resizing}
      bounds='.drag-area'
      dragGrid={[gridWidth, 40]}
      size={{ width: width,  height: 30}}
      position={position}
      onClick={handleOnClick}
      onDrag={onDrag}
      onDragStop= {move}
    >
      <div className='milestone'>
        { shape === DIAMOND && <Diamond $colour={colour} $borderColour={borderColour} $size={20} $radius={RADIUS[barStyle] !== undefined ? RADIUS[barStyle] : RADIUS[SOFTENED]}/>}
        { shape !== DIAMOND && <Circle $colour={colour} $borderColour={borderColour} $size={28}/>}
        <Label $colour={borderColour} width={width}>{label}</Label>
      </div>
    </StyledBar>
  )
};

const StyledBar = styled(Rnd)`
  cursor: pointer !important;
  opacity: ${p => p.$opacity};
  ${p => p.$zindex}
`;

const Circle = styled.div`
  background-color: ${p => p.$colour};
  border:1px solid ${p => p.$borderColour};
  height:${p => p.$size}px;
  border-radius:50%;
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
  width:${p => p.$size}px;
  margin-left: 1px;
`

const Diamond = styled.div`
    background-color: ${p => p.$colour};
    border:1px solid ${p => p.$borderColour};
    height:${p => p.$size}px;
    text-align: center;
    transform:rotate(45deg);
    width:${p => p.$size}px;
    margin-top: 4px;
    margin-left: 5px;
    border-radius: ${p => p.$radius}px;
`

const Label = styled.span`
  color: ${p => p.$colour};
  margin-left: 5px;
  position: relative;
  top: 1px;
  font-weight: 600;
  white-space: nowrap;
`

export default Milestone;
