import React from 'react';
import './App.css';
import Toolbar from './components/Toolbar'
import SettingsDialog from './components/SettingsDialog'
import LoginDialog from './components/LoginDialog'
import ProjectListDialog from './components/ProjectListDialog'
import Grid from './components/GridContainer'
import ProjectName from './components/ProjectName'
import { isMobile } from 'react-device-detect'
import {useSelector, useDispatch} from 'react-redux'
import { LATEST_VERSION } from './Version';
import { updateProject } from './actions/ProjectListActions';

const App = () => {
  const [open, setOpen] = React.useState(false);
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [projectListOpen, setProjectListOpen] = React.useState(false);

  const version = useSelector(s => s.config.version)
  let upgrading = LATEST_VERSION > version
  const dispatch = useDispatch()
  const curProject = useSelector(s => s.project)

  const showProjectList = () => {
    dispatch(updateProject(curProject))
    setProjectListOpen(true)
  }

  return (
    <>
    {!upgrading &&
    <>
      <Toolbar showSettings={() => setOpen(true)} showLogin={() => setLoginOpen(true)} showProjects={showProjectList} large={isMobile}/>
      <div className="bg">
        <ProjectName />
        <Grid />
      </div>
      <SettingsDialog open={open} onClose={() => setOpen(false)} />
      <ProjectListDialog open={projectListOpen} onClose={() => setProjectListOpen(false)} />
      <LoginDialog open={loginOpen} onClose={() => setLoginOpen(false)} />
    </>
    }
    {upgrading &&
    <div className="bg">
      <h1 className='title-bg'>Upgrading&nbsp;&nbsp;<img src='/favicon-32x32.png'></img></h1>
      <p className='title-bg'>Version {version} → {LATEST_VERSION}</p>
    </div>
    }
    </>
  )
}

export default App;
