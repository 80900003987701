import React, { useState, useEffect } from 'react';
import '../App.css';

const Label = (props) => {
  const {value, onSave, onCancel, className='name'} = props;
  const [name, setName] = useState(value)
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    setName(value)
  },[value])

  const save = () => {
    onSave(name)
    setEditMode(false)
  }
  
  const cancel = () => {
    setName(value)
    setEditMode(false)
    if (onCancel) {
      onCancel()
    }  
  }

  return (
    <div className={'label-' + className} onClick={() => !editMode && setEditMode(true)}>
    { !editMode && <div className={className}>{value && value.length > 0 ? value : 'New'}</div> }
    { editMode && 
      <input 
        className={'input-' + className} 
        value={name} 
        autoFocus={true} 
        onKeyDown={
          (e) => {
            if (e.key === 'Enter'){
              save()
            } else if (e.key === 'Escape'){
              cancel()
            } 
          }
        }
        onBlur={() => save()}
        contentEditable={true}
        onChange={(e) => setName(e.target.value) }
      />
    }
    </div>
  )  
}

export default Label;
