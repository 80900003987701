import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import { updateTask, addTask, deleteTask, TASK, HEADING, MILESTONE, WEEKLY, ROADMAP, CIRCLE, DIAMOND } from '../actions/ProjectActions'
import TrackPosition from './TrackPosition';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { CompactPicker } from 'react-color'
import TrackCalendar from './TrackCalendar';
import { FiCopy } from 'react-icons/fi'
import { MdDelete } from 'react-icons/md'
import { v4 as uuidv4 } from 'uuid'

/**
 * Edit dialog for a project task.
 */
function EditDialog(props) {
  const { onClose, selectedValue, open } = props;
  const task = useSelector(state => state.project.editTask)
  const timeline = useSelector(state => state.project.timeline)
  const projectType = useSelector(state => state.project.type)
  const dispatch = useDispatch()


  const handleClose = () => {
    onClose(selectedValue);
  }

  const onNameChange = (name) => {
    dispatch(updateTask(task.id, {name: name}))
  }

  const onTypeChange = (type) => {
    if (type === TASK && !(task.start && task.length)) {
      dispatch(updateTask(task.id, {type: type, start: timeline.index, length: 8}))
    } else if (type === MILESTONE) {
      dispatch(updateTask(task.id, {type: type, length: 1}))
    } else {
      dispatch(updateTask(task.id, {type: type}))
    }
  }

  const onShapeChange = (shape) => {
    dispatch(updateTask(task.id, {shape: shape}))
  }

  function darken(c1, delta) {
    const asInt = parseInt(c1, 16)
    if (asInt - delta < 0) {
      return '00'
    }
    var hexStr = (asInt - delta).toString(16);
    if (hexStr.length === 1) hexStr = '0' + hexStr
    return hexStr;
  }

  function border(c) {
      if ( c.length === 7) {
        let border = '#' + darken(c.substring(1, 3), 18) + darken(c.substring(3, 5), 18) + darken(c.substring(5, 7), 18)
        return border
      }

      return c
  }

  function handleColourChange(c, e) {
    dispatch(updateTask(task.id, {colour: c.hex, borderColour: border(c.hex)}))
  }

  const newTaskLength = (task.start + task.length + task.length <= timeline.trackLength) ? task.length : timeline.trackLength - task.start - task.length
  const onCopy = () => {
    if (newTaskLength > 0) {
        dispatch(addTask({id: uuidv4(), row: task.row, name: task.name, type: task.type, start: task.start + task.length, length: newTaskLength, colour: task.colour, borderColour: task.borderColour, shape: task.shape}))
    }
  }

  const onDelete = () => {
    dispatch(deleteTask(task))
    onClose();
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <div className='edit-toolbar'>
            { <div onClick={() => onCopy()} className={"button-enabled " + (newTaskLength > 0 ? "" : "button-disabled")}><FiCopy title={(newTaskLength > 0 ? "Duplicate this task" : "End of track - no room to duplicate")}/></div>}
            <div onClick={() => onDelete()} className="delete-button-enabled"><MdDelete title="Delete this task"/></div>
        </div>
      <List>
        <ListItem>
            <TextField className='edit-name' id="standard-basic" label="Name" defaultValue={task.name} onChange={e => onNameChange(e.target.value)}/>
        </ListItem>
        <ListItem>
          <div>
            <Typography className='MuiInputLabel-shrink' color="textSecondary">Type</Typography>
            <Select native value={ task.type } onChange={ (e) => onTypeChange(e.target.value) }>
              <option key='type1' value={TASK}>Task</option>
              <option key='type2' value={HEADING}>Heading</option>
              <option key='type3' value={MILESTONE}>Milestone</option>
            </Select>
          </div>

        </ListItem>
        <ListItem>
          <div>
          <Typography className='MuiInputLabel-shrink' color="textSecondary">Bar color</Typography>
          <CompactPicker color={task.colour} onChange={handleColourChange} />
          </div>
        </ListItem>
        { task.type === MILESTONE && projectType === WEEKLY &&
        <ListItem>
            <TrackCalendar label='Date' value={task.start} handleChange={(value) => dispatch(updateTask(task.id, {start: value}))} />
        </ListItem> }
        { task.type === TASK && projectType === ROADMAP &&
        <ListItem>
            <TrackPosition label='Start' value={task.start} handleChange={(value) => dispatch(updateTask(task.id, {start: value}))} />
        </ListItem> }
        { task.type === TASK && projectType === WEEKLY &&
        <ListItem>
            <TrackCalendar label='Start' value={task.start} handleChange={(value) => dispatch(updateTask(task.id, {start: value}))} />
        </ListItem> }
        { task.type === TASK && projectType === WEEKLY &&
        <ListItem>
            <TrackCalendar label='End' value={task.start + task.length - 1} handleChange={(value) => dispatch(updateTask(task.id, {length: value - task.start + 1}))} />
        </ListItem> }
        { task.type === TASK && projectType === ROADMAP &&
        <ListItem>
            <TrackPosition
              label='End'
              value={task.start + task.length - 1}
              isEnd={true}
              min={task.start - task.start % 8}
              handleChange={(value) => dispatch(updateTask(task.id, {length: value - task.start + 1}))}
            />
        </ListItem> }
        { task.type === MILESTONE &&
        <ListItem>
          <div>
            <Typography className='MuiInputLabel-shrink' color="textSecondary">Shape</Typography>
            <Select native value={ task.shape } onChange={ (e) => onShapeChange(e.target.value) }>
              <option key='shape1' value={CIRCLE}>Circle</option>
              <option key='shape2' value={DIAMOND}>Diamond</option>
            </Select>
          </div>
        </ListItem> }
      </List>
    </Dialog>
  );
}

EditDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
}

export default EditDialog