export const convertV5Timeline = (config) => {
    return {
        track: config.track,
        index: config.index,
        columns: config.columns,
        increments: config.increments,
        justifyLeft: config.justifyLeft,
        windowLength: config.windowLength,
        trackLength: config.trackLength,
        incrementWidth: config.incrementWidth,
        labelColWidth: config.labelColWidth,
        displayMode: config.displayMode,
        columnWidth: config.columnWidth,
        defaultTaskLength: 8,
        maxColumns: 12,
    }
}