export function moveItemForward(list, index) {
    return swapItems(list, index - 1)
}

export function moveItemBack(list, index) {
    return swapItems(list, index)
}

export function swapItems(list, index) {
    if (index < 0 || index + 1 > list.length - 1) {
        return list
    }

    return [
        ...list.slice(0, index),
        list[index + 1],
        list[index],
        ...list.slice(index + 2)
    ]
}

export function moveItem(list, index, target) {

    if (target > index && target < list.length) {
        return [
            ...list.slice(0, index),
            ...list.slice(index + 1, target + 1),
            list[index],
            ...list.slice(target + 1)
        ]
    } else if (target < index && index < list.length && target >= 0) {
        return [
            ...list.slice(0, target),
            list[index],
            list[target],
            ...list.slice(target + 1, index),
            ...list.slice(index + 1)
        ]
    }

    return list

}

export function isoDateFormat(date) {
    return date.toLocaleString('sv').slice(0, 10)
}