const upgrade = (currentState, upgrades, config) => {
  let newVersion = Object.assign({}, currentState)
  console.log("before...")
  console.log(currentState)
  for (const upgrade of upgrades) {
    if (!currentState.version || upgrade.version > currentState.version) {
      console.log("upgrading to", upgrade.version)
      newVersion = Object.assign(newVersion, upgrade.state, {version: upgrade.version})
      if (upgrade.action) {
        newVersion = upgrade.action(newVersion, config)
      }
    }
  }
  console.log("after...")
  console.log(newVersion)
  return newVersion
}

/*
const example_upgrade = [
  {
    version: 5,
    state: {
      projects: [],
      projectIndex: 0,
    },
    action: (state) => {
      let project1 = {}
      project1['name'] = Object.assign({}, state.name)
      project1['tasks'] = [...state.tasks]
      return {
        ...state,
        project: project1,
        projects: [...state.projects, Object.assign({}, project1)]
      }
    }
  },
]
*/
export default upgrade;