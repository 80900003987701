import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

function TrackPosition(props) {
  const {label, value, handleChange, isEnd=false, min=0} = props
  const track = useSelector(state => state.project.timeline.track)

  function convertTrack() {
      let options = []
      for (let i = min; i < track.length; i++) {
          if (track[i].length > 0) {
            options.push({index: i, values: track[i]})
          }
      }

      return options
  }

  const options = convertTrack()

  function buildOptions() {
    let selOptions = []

    for (let i = 0; i < options.length; i++) {
      selOptions.push((<option key={isEnd + '_' + options[i].index} value={i}>{options[i].values[0].name}</option>))
    }
    return selOptions
  }

  let selectOptions = buildOptions()

  function findSelected() {
    const selectedIndex = (value - (value % 8))
    for (let i = options.length - 1; i >= 0; i--) {
      if (options[i].index === selectedIndex) {
        return i
      }
    }
    return 0
  }

  const selected = findSelected()
  const slider = value % 8 + 1
  const marksStart = [{value: 1, label: (<span className='step-slider-mark'>start</span>)}, {value: 2, label: ''}, {value: 3, label: (<span className='step-slider-mark'>early</span>)}, {value: 4, label: ''}, {value: 5, label: (<span className='step-slider-mark'>mid</span>)}, {value: 6, label: ''}, {value: 7, label: (<span className='step-slider-mark'>late</span>)}, {value: 8, label: ''}]
  const marksEnd = [{value: 1, label: ''}, {value: 2, label: (<span>early</span>)}, {value: 3, label: ''}, {value: 4, label: (<span>mid</span>)}, {value: 5, label: ''}, {value: 6, label: (<span>late</span>)}, {value: 7, label: ''}, {value: 8, label: (<span className='step-slider-mark-end'>end</span>)}]

  return (
    <div>
      <Typography className='MuiInputLabel-shrink' color="textSecondary">{label}</Typography>
      <Select
        native
        value={selected}
        className='start-select'
        onChange={(e) => {
          handleChange(value + (e.target.value - selected) * 8)
        }}
      >
        {selectOptions}
      </Select>
      <Slider
          className='step-slider'
          value={slider}
          step={null}
          marks={isEnd? marksEnd :  marksStart}
          min={1}
          max={8}
          valueLabelDisplay='off'
          track={isEnd ? 'normal' : 'inverted'}
          onChange={(e, v) => {
            if (v !== slider) {
              handleChange(value + v - slider)
            }
          }}
      />
    </div>
  );
}

TrackPosition.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  handleChange: PropTypes.any
}

export default TrackPosition