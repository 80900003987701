import { ACCEPT_TERMS, SET_ACCOUNT, SET_MODE } from '../actions/AccountActions'
import { UPGRADE } from '../actions/ConfigActions'
import { LATEST_VERSION } from '../Version'
import upgrade from './Upgrader'

const initialState = {
  version: LATEST_VERSION,
  acceptedTerms: false,
  acceptedDate: null,
  mode: {},
}

const upgrades = [
  {
    version: 5,
    state: {
      mode: {}
    }
  },
  {
    version: LATEST_VERSION,
    state: {}
  }
]

const accountReducer = function (state = initialState, action) {
    switch (action.type) {
      case ACCEPT_TERMS:
        return {
          ...state,
          acceptedTerms: true,
          acceptedDate: new Date()

        }
      case SET_ACCOUNT:
        return {
          ...state,
          userId: action.userId,
          token: action.token,
        }
      case SET_MODE:
        const newMode = Object.assign({}, state.mode)
        if (action.mode === "alpha") {
          newMode["isAlpha"] = true
        }
        return {
          ...state,
          mode:  newMode,
        }
      case UPGRADE: {
          if (initialState.version > state.version) {
            return upgrade(state, upgrades)
          }
          return state
        }
      default:
        return state;
    }
  }

export { accountReducer }