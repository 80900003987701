import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent'
import { checkToolbarWidth } from '../actions/ConfigActions';
import ProjectEditSettings from './ProjectEditSettings';

function SettingsDialog(props) {
  const { open, onClose } = props;
  const dispatch = useDispatch()

  const { version } = useSelector(p => p.config)
  const { timeline } = useSelector(p => p.project)

  useEffect(() => {
    if (open) {
      dispatch(checkToolbarWidth(timeline))
    }
  }, [timeline])

  const handleClose = () => {
    onClose();
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogContent className='settings' >
          <ProjectEditSettings open={ open }/>
          <div className='project-settings'>
            <span className="label label-disabled">Build v{process.env.REACT_APP_VERSION}</span>&nbsp;&nbsp;&nbsp;
            <span className="label label-disabled">Data format: v{version}</span>
          </div>
      </DialogContent>
    </Dialog>
  );
}

SettingsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default SettingsDialog