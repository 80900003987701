export const ADD_TASK = 'ADD_TASK'
export const MOVE_TASK = 'MOVE_TASK'
export const MOVE_UP = 'MOVE_UP'
export const MOVE_DOWN = 'MOVE_DOWN'
export const UPDATE_TASK = 'UPDATE_TASK'
export const RESIZE_TASK = 'RESIZE_TASK'
export const EDIT_TASK = 'EDIT_TASK'
export const DELETE_TASK = 'DELETE_TASK'
export const DELETE_ROW = 'DELETE_ROW'
export const EDIT_DONE = 'EDIT_DONE'
export const UPDATE_NAME = 'UPDATE_NAME'
export const UPDATE_STRICT_GANTT = 'UPDATE_STRICT_GANTT'
export const UPDATE_STYLE = 'UPDATE_STYLE'
export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT'
export const CREATE_PROJECT = 'CREATE_PROJECT'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'

export const TASK = 'task'
export const HEADING = 'heading'
export const MILESTONE = 'milestone'

export const TRACK_LEFT = 'TRACK_LEFT'
export const TRACK_RIGHT = 'TRACK_RIGHT'
export const UPDATE_LABEL_COL_WIDTH = 'UPDATE_LABEL_COL_WIDTH'
export const UPDATE_COLUMNS = 'UPDATE_COLUMNS'
export const DISPLAY_MODE = 'DISPLAY_MODE'

export const ROADMAP = 'ROADMAP'
export const WEEKLY = 'WEEKLY'
export const ROUNDED = 'ROUNDED'
export const SOFTENED = 'SOFTENED'
export const SHARP = 'SHARP'
export const CIRCLE = 'CIRCLE'
export const DIAMOND = 'DIAMOND'

export function trackLeft() {
    return {
        type: TRACK_LEFT
    }
}

export function trackRight() {
    return {
        type: TRACK_RIGHT
    }
}

export function updateColumns(columns) {
    return {
        type: UPDATE_COLUMNS,
        columns: columns
    }
}

export function updateLabelColWidth(width) {
    return {
        type: UPDATE_LABEL_COL_WIDTH,
        width: width
    }
}

export function updateDisplayMode(mode) {
    return {
        type: DISPLAY_MODE,
        mode: mode
    }
}

export function addTask(task) {
    return {
        type: ADD_TASK,
        task: task
    }
}

export function deleteTask(task) {
    return {
        type: DELETE_TASK,
        task: task
    }
}

export function deleteRow(rowIndex) {
    return {
        type: DELETE_ROW,
        rowIndex: rowIndex,
    }
}

export function moveTask(task, start) {
    return {
        type: MOVE_TASK,
        task: task,
        start: start
    }
}

export function moveUp(task, by = 1) {
    return {
        type: MOVE_UP,
        task: task,
        by: by
    }
}

export function moveDown(task, by = 1) {
    return {
        type: MOVE_DOWN,
        task: task,
        by: by
    }
}

export function updateTask(id, changes) {
    return {
        type: UPDATE_TASK,
        taskId: id,
        changes: changes
    }
}

export function resizeTask(task, length) {
    return {
        type: RESIZE_TASK,
        task: task,
        length: length
    }
}

export function setEditTask(task) {
    return {
        type: EDIT_TASK,
        task: task
    }
}

export function editDone() {
    return {
        type: EDIT_DONE
    }
}

export function updateName(name) {
    return {
        type: UPDATE_NAME,
        name: name
    }
}

export function updateStrictGantt(strictGantt) {
    return {
        type: UPDATE_STRICT_GANTT,
        strictGantt: strictGantt
    }
}

export function updateStyle(style) {
    return {
        type: UPDATE_STYLE,
        style: style
    }
}

export function setCurrentProject(project) {
    return {
        type: SET_CURRENT_PROJECT,
        project: project
    }
}

export function createProject(project) {
    return {
        type: CREATE_PROJECT,
        project: project
    }
}

export function saveProject(project) {
    return async (dispatch, getState) => {
        const state = getState()
        const token = state.account.token
        const apiDomain = state.config.apiDomain

        const config = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(project)
        };
        try {
            const fetchResponse = await fetch(`${apiDomain}/project`, config);
            const data = await fetchResponse.json();
            console.log(data)
            return data;
        } catch (e) {
            return e;
        }
    }
}

