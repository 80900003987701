const prodConfig = {
  apiDomain: 'https://api.ganttbuster.com',
  origin: 'ganttbuster.com',
}

const devConfig = {
  apiDomain: 'http://127.0.0.1:8088',
  origin: 'localhost:3000',
}

export default () => {
  if (process.env.NODE_ENV === 'production') {
    return prodConfig
  } 
  return devConfig
}
