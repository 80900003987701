import React from 'react'
import '../App.css'
import { MdEdit, MdDelete } from 'react-icons/md'

const EditButtons = ({task, row, mode, openEdit, onDelete}) => {
  const row2 = row + 1

  const doGA = (action) => {}

  return (
    <div style={{ gridColumn: '2 / 3', gridRow: `${row} / ${row2}`}} className='edit-icon'>
      { mode === 'EDIT' && <MdEdit color='gray' onClick={() => {openEdit(task); doGA('edit')}} title="Edit task"/> }
      { mode === 'DELETE' && <MdDelete color='gray' onClick={() => {onDelete(task) ; doGA('delete')}}  title="Delete row"/> }
    </div>
  )
};

export default EditButtons;
