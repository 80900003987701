import React, { useState, useEffect } from 'react';
import '../App.css';
import { Rnd } from "react-rnd";
import styled from 'styled-components'
import { debounce } from "debounce"
import { SOFTENED, ROUNDED, SHARP } from '../actions/ProjectActions';

const CUTOFFS = {
    ROUNDED: {
        none: 'bar bar-start-rounded bar-end-rounded',
        start: 'bar bar-end-rounded',
        end: 'bar bar-start-rounded',
        both: 'bar'
    },
    SOFTENED: {
        none: 'bar bar-start bar-end',
        start: 'bar bar-end',
        end: 'bar bar-start',
        both: 'bar'
    },
    SHARP: {
        none: 'bar',
        start: 'bar',
        end: 'bar',
        both: 'bar'
    }
}

const resizing = {
  bottom: false,
  bottomLeft: false,
  bottomRight: false,
  left: true,
  right: true,
  top: false,
  topLeft: false,
  topRight: false
}

const Bar = ({
  start,
  width,
  updatePosition,
  resizeTask,
  cutoff="none",
  colour='#3771f3',
  borderColour='#2558cc',
  label='',
  onClick,
  gridWidth,
  barStyle=ROUNDED,
}) => {
  const barClassName =  CUTOFFS[barStyle] !== undefined ? CUTOFFS[barStyle][cutoff] : CUTOFFS[ROUNDED][cutoff]
  const [isAdjusting, setIsAdjusting] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [position, setPosition] = useState({x: start, y: 0})

  useEffect(() => {
    setPosition({x: start, y: 0})
  }, [start])

  function handleOnClick() {
    if (!isAdjusting) {
      onClick()
    }
    setIsAdjusting(false)
  }

  function clear() {
    setIsAdjusting(false)
    setIsDragging(false)
  }

  const onDrag = (e, data) => {
    setIsAdjusting(true);
    setIsDragging(true);
    setPosition({x: data.x, y: data.y})
  }

  const stoppedAdjusting = debounce(clear, 200)

  /* debounce to avoid updating state issue in react-draggable */
  const move= debounce((e, data) => updatePosition(data.x, data.y), 0)

  return (
    <StyledBar
      className={barClassName}
      colour={colour}
      zindex={isAdjusting ? 'z-index: 10;' : ''}
      opacity={isDragging ? '0.9' : '1.0'}
      border={borderColour}
      enableResizing={resizing}
      bounds='.drag-area'
      dragGrid={[gridWidth, 40]}
      resizeGrid={[gridWidth,1]}
      size={{ width: width,  height: 30}}
      position={position}
      onClick={handleOnClick}
      onDrag={onDrag}
      onResize={() => setIsAdjusting(true)}
      onDragStop= {move}
      onResizeStop={(e, direction, ref, delta, position) => {
        resizeTask(parseInt(delta.width))
        if (cutoff === 'none' || cutoff === 'end') {
          updatePosition(position.x)
        }
        stoppedAdjusting()
      }}
    ><span>{label}</span>
    </StyledBar>
  )
};

const StyledBar = styled(Rnd)`
  background: ${p => p.colour};
  border-color: ${p => p.border};
  cursor: pointer !important;
  opacity: ${p => p.opacity};
  ${p => p.zindex}
`;


export default Bar;
