import { v4 as uuidv4 } from 'uuid'
import { upgradeProjects } from '../reducers/ProjectUpgrader'
import { LATEST_VERSION } from '../Version'

import { createProject, ROADMAP, ROUNDED, setCurrentProject } from './ProjectActions'

export const ADD_PROJECT = 'ADD_PROJECT'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const ARCHIVE_PROJECT = 'ARCHIVE_PROJECT'
export const SELECT_PROJECT = 'SELECT_PROJECT'
export const SELECT_PROJECT_BY_ID = 'SELECT_PROJECT_BY_ID'


export function addProject(project, token) {
    return {
        type: ADD_PROJECT,
        project: project,
        token: token,
    }
}

export function updateProject(project) {
    return {
        type: UPDATE_PROJECT,
        project: project,
    }
}

export function archiveProject(project) {
    return {
        type: ARCHIVE_PROJECT,
        project: project,
    }
}

export function selectProjectById(projectId) {
    return {
        type: SELECT_PROJECT_BY_ID,
        projectId: projectId
    }
}

export function selectProject(index) {
    return {
        type: SELECT_PROJECT,
        index: index
    }
}

export function findProjectIndex(projectList, project) {
    for (let i = 0; i < projectList.length; i++) {
      if (projectList[i].id === project.id) {
        return i;
      }
    }
    return -1;
}

export function saveProject(project) {
    return async (dispatch, getState) => {
        const state = getState()
        const token = state.account.token
        const apiDomain = state.config.apiDomain

        const config = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(project)
        };
        try {
            const fetchResponse = await fetch(`${apiDomain}/project`, config);
            const data = await fetchResponse.json();
            console.log(data)
            return data;
        } catch (e) {
            return e;
        }
    }
}

export function loadProject(project_token) {
    return async (dispatch, getState) => {
        const state = getState()
        const token = state.account.token
        const apiDomain = state.config.apiDomain
        const prevProject = state.project

        const config = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        };
        try {
            const fetchResponse = await fetch(`${apiDomain}/share/project/${project_token}`, config);
            if (fetchResponse.status === 200) {
                const project = await fetchResponse.json();
                dispatch(addProject(project, project_token))
                dispatch(updateProject(prevProject))
                dispatch(setCurrentProject(project))
            } else {
                console.log(fetchResponse.text)
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export function changeProject(project) {
    return (dispatch, getState) => {
        const state = getState()
        const prevProject = state.project
        dispatch(updateProject(prevProject))
        dispatch(setCurrentProject(project))
        dispatch(updateProject(project))
    }
}

export function importProjects(projects) {
    return (dispatch, getState) => {
        const state = getState()
        const prevProject = state.project
        dispatch(updateProject(prevProject))
        let upgradedProjects = upgradeProjects(projects, LATEST_VERSION, state.config)
        upgradedProjects.forEach((p) => {
            dispatch(updateProject(p))
        })
        dispatch(setCurrentProject(upgradedProjects[0]))
    }
}



export function newProject(name, type, start, end) {
    return (dispatch, getState) => {
        const state = getState()
        const prevProject = state.project
        dispatch(updateProject(prevProject))
        const newProject = createNewProject(name, type, start, end)
        dispatch(createProject(newProject))
        dispatch(updateProject(newProject))
    }
}

function createNewProject(name="Roadmap", type=ROADMAP, start, end) {
    return {
        id: uuidv4(),
        name: name,
        type: type,
        startDate: start,
        endDate: end,
        tasks: [],
        numRows: 0,
        style: ROUNDED,
        strictGantt: true,
        lastUpdated: new Date().getTime()
    }
}
