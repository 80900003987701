import { connect } from 'react-redux'
import Grid from './Grid'
import { moveTask, resizeTask, moveUp, moveDown } from '../actions/ProjectActions'

const mapDispatchToProps = {
  moveTask,
  resizeTask,
  moveUp,
  moveDown,
}

const mapStateToProps = state => ({
  tasks: state.project.tasks,
  index: state.project.timeline.index,
  editMode: state.config.editMode,
  displayMode: state.project.timeline.displayMode,
  columns: state.project.timeline.columns,
  windowLength: state.project.timeline.windowLength,
  columnWidth: state.project.timeline.columnWidth,
  incrementWidth: state.project.timeline.incrementWidth,
  labelColWidth: state.project.timeline.labelColWidth,
  increments: state.project.timeline.increments,
  timeline: state.project.timeline,
  numRows: state.project.numRows,
})

export default connect(mapStateToProps, mapDispatchToProps)(Grid)
