import React from 'react'
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import { acceptTerms } from '../actions/AccountActions'
import { addProject } from '../actions/ProjectListActions'
import { useEffect } from 'react'

const Cookies = ({ hasAccepted }) => {
    const acceptedTerms = useSelector(state => state.account.acceptedTerms)
    const project = useSelector(state => state.project)
    const dispatch = useDispatch()
    const handleAccept = () => {
        hasAccepted()
        dispatch(acceptTerms())
        dispatch(addProject(project))
    }

    useEffect(() => {
        if (acceptedTerms) {
            hasAccepted()
        }
    }, [])

    if (acceptedTerms) {
        return (null)
    }

    return (
        <div className='cookies'>
            <h3>Privacy information and Terms of use</h3>
            <div className='cookie-content'>
                <p>This site uses cookies and local storage to be able to support things like: site functionality, storing your content and settings, and site usage analysis.
                You can find out more in our <a href='/privacy.html'>privacy policy</a> and <a href='/terms.html'>terms and conditions</a></p>
                <p>By continuing to use this site you acknowledge that you accept our <a href='/terms.html'>terms and conditions</a>.</p>
            </div>
            <Button variant="contained" color="primary" onClick={() => handleAccept()}>I'm OK with that</Button>
        </div>
    )
}

export default Cookies
