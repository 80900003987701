import React from 'react';
import '../App.css';

const TrackHeading = ({col1, col2, row, label, className = 'time2'}) => {
  return (
    <div style={{ gridColumn: `${col1} / ${col2}`, gridRow: `${row} / ${row + 1}`}} className={className}>{label}</div>
  )
}

export default TrackHeading;
