import React from 'react';
import {useSelector} from 'react-redux';
import Typography from '@mui/material/Typography';

function TrackCalendar(props) {
  const DAY_TRACK = 3;
  const {label, value, handleChange, isEnd=false, min=0} = props
  const track = useSelector(state => state.project.timeline.track)
  const trackSlot = track[value]
  var mydate = trackSlot.filter(e => e.row === DAY_TRACK)[0].value

  const dateToTrack = (date) => {
    for (let i = 0; i < track.length; i++) {
        let trackSlot = track[i]
        if (trackSlot.filter(e => e.row === DAY_TRACK)[0].value === date) {
            return i
        }
    }

    return 0
  }

 const handleDateChange = (e) => {
    let newStart = dateToTrack(e.target.value)
    handleChange(newStart)
 }

  return (
    <div>
      <Typography className='MuiInputLabel-shrink' color="textSecondary">{label}</Typography>
      <input type='date' value={mydate} onChange={handleDateChange}></input>
    </div>
  );
}

export default TrackCalendar