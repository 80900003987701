import React from 'react';
import { useDispatch } from 'react-redux';
import '../App.css';
import GridBarRow from './GridBarRow';
import TaskLabel from './TaskLabel';
import styled from 'styled-components'
import {setEditTask, TASK, MILESTONE, deleteRow} from '../actions/ProjectActions'
import EditButtons from './EditButtons';
import EditDialog from './EditDialog'
import TrackHeadings from './TrackHeadings'
import { PROJECT } from '../actions/ConfigActions'

const Grid = (props) => {
  const dispatch = useDispatch()
  const {
    tasks,
    index,
    moveTask,
    moveUp,
    moveDown,
    resizeTask,
    editMode,
    displayMode,
    columns,
    windowLength,
    columnWidth,
    incrementWidth,
    labelColWidth,
    increments,
    timeline,
    numRows,
   } = props

  const [open, setOpen] = React.useState(false);
  if (!timeline || !timeline.track) {
    return (<div>Sorry something has gone wrong with this project.</div>)
  }

  const columnStart = editMode ? 3 : 2

  let maxColumns = (columns * increments)
  if (maxColumns > timeline.track.length) {
    maxColumns = timeline.track.length
  }
  maxColumns += (columnStart - 1)
  const columnEnd = maxColumns + 1
  const showLabelCol = displayMode === PROJECT
  const labelWidth = editMode ? labelColWidth - 31 : labelColWidth

  const handleClickOpen = (task) => {
    dispatch(setEditTask(task))
    setOpen(true);
  }

  const onDelete = (task) => {
    dispatch(deleteRow(task.row))
  }

  const handleClose = () => {
    setOpen(false);
  }

  function buildFiller(row) {
    return buildRow(columnStart, maxColumns, increments, row, 'row-spacer' )
  }

  const incWidth = incrementWidth
  const viewEnd = windowLength * incWidth + 1

  function buildBarRow(row, index, tasks) {
    let newRow =  buildRow(columnStart, maxColumns, increments, row, 'bar-row' )
    let taskCount = 1;
    for (const task of tasks) {
        let cutoff = 'none'
        let offset = task.start - index
        let start = offset * incWidth
        let length = task.length * incWidth
        let offcut = 0;

        if (offset < 0) {
            length = length + start > 0 ? length + start : 0
            start = 0
            cutoff = 'start'
            offcut = offset
        }

        if (start + length > viewEnd) {
            length = viewEnd - start
            cutoff =  cutoff === 'start' ? 'both' : 'end'
        }

        const updatePosition = (x, y) => {
            moveTask(task, Math.round((x/incWidth) + index + offcut))
            const delta = Math.floor((Math.abs(y) + 20)/40)
            if (delta > 0) {
                if (y > 0) {
                moveDown(task, delta)
                } else {
                moveUp(task, delta)
                }
            }
        }

        if (task.type === TASK || task.type === MILESTONE) {
            newRow.push(
                <GridBarRow
                    key={'row_' + task.id}
                    task={task}
                    start={start}
                    length={length}
                    cutoff={cutoff}
                    row={row}
                    updatePosition={updatePosition}
                    resizeTask={ (x) => resizeTask(task, Math.round(x/incWidth) + task.length) }
                    showEdit={editMode}
                    openEdit={handleClickOpen}
                    onDelete={onDelete}
                    writeName={taskCount === 1}
                />
            )
        } else {

            if (showLabelCol) {
                newRow.push(
                <Heading key={'h_' + task.id} style={{ gridColumn: '1 / 2', gridRow: `${row} / ${row + 1}`}} $showEdit={editMode} $labelColWidth={labelColWidth}>
                    <TaskLabel key={'h_l_' + task.id} task={task} className={'heading'} />
                </Heading>
                )
            } else {
                newRow.push(
                <div key={'h_' + task.id} className={'sl-top'} style={{ gridColumn: `${columnStart + ' / ' + columnEnd}`, gridRow: `${row} / ${row + 1}`}}>
                    <TaskLabel key={'h_l_' + task.id} task={task} className={'heading-sl'} />
                </div>
                )
            }
            if (editMode) {
                newRow.push(
                <EditButtons key={'eb_' + task.id} task={task} row={row} mode={editMode} openEdit={handleClickOpen} onDelete={onDelete} />
                )
            }
        }
        taskCount++
    }

    return newRow
  }

  function buildRow(colStart, colEnd, increments, row, className = 'row-spacer') {
    let filler = []
    let row2 = row + 1;
    for (let i = colStart; i <= colEnd; i+=increments) {
      let col2 = i+increments;
      filler.push(<div key={row + '_' + i} style={{ gridColumn: `${i} / ${col2}`, gridRow: `${row} / ${row2}`}} className={className}></div>)
    }

    return filler;
  }

  function buildTasks(tasks, lastRow, index) {
    var rowIndex = lastRow;
    const content = []
    for ( let i = 0; i < numRows; i++) {
        const row = tasks.filter(t => t.row === i)
        if (row.length > 0) {
            content.push(buildBarRow(rowIndex++, index, row))
        }
    }

    return content
  }

  const startsWithHeading = tasks && tasks[0] && tasks[0].type === 'heading';

  const rowSizes = () => {
     let sizes = ""
     for ( let i = 0; i < numRows; i++) {
         sizes += ' 40px'
     }
     return sizes
  }

  const colSizes = () => {
    let sizes = ""
    for (var i = 0; i < columns * increments; i++) {
        sizes += (i === (columns * increments) - 1) ? (incrementWidth) + 'px ' : (incrementWidth - 1) + 'px '
    }
    return sizes
 }


  if (showLabelCol) {
  return (
    <>
      <GridContainer
        className='grid'
        $showEdit={editMode}
        $showLabelCol={showLabelCol}
        $colWidth={incrementWidth - 1}
        $labelColWidth={labelWidth}
        $startsWithHeading={startsWithHeading}
        $rowSizes={rowSizes}
        $colSizes={colSizes}
      >
        <TrackHeadings />
        { buildFiller(3) }
        <DragArea
            className='drag-area'
            $showEdit={editMode}
            $showLabelCol={showLabelCol}
            $width={viewEnd}
            $labelColWidth={labelWidth}
            $height={numRows * 40}
            $headingsHeight={startsWithHeading ? 50 : 70}
          />
        { buildTasks(tasks, 4, index) }
      </GridContainer>
      <EditDialog selectedValue='foo' open={open} onClose={handleClose} />
    </>
  )
    } else {
      return (
        <>
          <GridContainerNoLabelCol
            className='grid'
            $showEdit={editMode}
            $showLabelCol={showLabelCol}
            $colWidth={columnWidth - 1}
            $startsWithHeading={startsWithHeading}
            $rowSizes={rowSizes}
            $colSizes={colSizes}
          >
            <TrackHeadings />
            { buildFiller(3) }
            <DragArea
              className='drag-area'
              $showEdit={editMode}
              $showLabelCol={showLabelCol}
              $labelColWidth={labelWidth}
              $width={viewEnd}
              $height={numRows * 40}
              $headingsHeight={startsWithHeading ? 53 : 70}
            />
            { buildTasks(tasks, 4, index) }
          </GridContainerNoLabelCol>
          <EditDialog selectedValue='foo' open={open} onClose={handleClose} />
        </>
      )
    }
}

const GridContainer = styled.div`
  grid-template-rows: 30px 20px ${p => p.$startsWithHeading?"0px":"20px"} ${p => p.$rowSizes};
  grid-template-columns: ${p => p.$showEdit ? p.$labelColWidth + 'px 30px' : p.$showLabelCol ? p.$labelColWidth + 'px' : '0px'}  ${p => p.$colSizes};
  grid-auto-columns: ${p => p.$incrementWidth};
  grid-auto-rows: 40px;
`
const GridContainerNoLabelCol = styled.div`
  grid-template-rows: 30px 23px ${p => p.$startsWithHeading?"0px":"20px"} ${p => p.$rowSizes};
  grid-template-columns: ${p => p.$showEdit ? ' 0px 30px' : '0px'}  ${p => p.$colSizes};
  grid-auto-columns: ${p => p.$incrementWidth};
  grid-auto-rows: 40px;
`

const DragArea = styled.div`
  margin-left: ${p =>  p.$showLabelCol ? (p.$labelColWidth + 1 + (p.$showEdit ? 31 : 0)) : (p.$showEdit ? 32 : 1)}px;
  width: ${p => (p.$width - 1)}px;
  height: ${p => p.$height}px;
  margin-top: ${p => p.$headingsHeight}px;
`

const Heading = styled.div`
    width: ${p => (p.$showEdit?'0 ':'') + p.$labelColWidth + 'px'};
`
export default Grid;
