import { createStore, applyMiddleware} from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from "redux-thunk";
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './reducers/Reducer'
import { checkUpgrade, setConfig, checkDeepLink, mobileDefaults } from './actions/ConfigActions';
import config from './config'
import { isMobile, isTablet } from 'react-device-detect'

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default () => {
  let store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));  
  let persistor = persistStore(store, null, () => {
    store.dispatch(setConfig(config()))
    store.dispatch(checkUpgrade())
    store.dispatch(checkDeepLink())
    if (isMobile && !isTablet) {
      store.dispatch(mobileDefaults(window.innerWidth))
    }
  })
  persistor.pause()
  
  return { store, persistor }
}
