import React, { useState } from 'react';
import Select from '@mui/material/Select';
import { Button, DialogActions, FormControl, InputLabel, TextField } from '@mui/material';
import { ROADMAP, WEEKLY } from '../actions/ProjectActions';
import { isoDateFormat } from '../reducers/utils';


function ProjectSettings({ handleClose, handleNew }) {
  const [projectName, setProjectName] = useState("")
  const [projectType, setProjectType] = useState("ROADMAP")
  const start = new Date()
  const end = new Date()
  end.setDate( end.getDate() + 365)
  const [startDate, setStartDate] = useState(isoDateFormat(start))
  const [endDate, setEndDate] = useState(isoDateFormat(end))
  const [descError, setDescError] = useState("")
  const [endDateError, setEndDateError] = useState("")

  function onTextChange(value) {
    setProjectName(value)
  }

  function onTypeChange(value) {
    setProjectType(value)
  }

  function handleCreate() {
    if (!validate()) {
      handleNew(projectName, projectType, new Date(startDate), new Date(endDate))
    }
  }

  function validate() {
    let hasError = false

    if (!projectName || projectName.length < 1) {
        hasError = true
        setDescError("Projects need a name")
    } else {
        setDescError("")
    }

    if ( new Date(endDate).getTime() < new Date(startDate).getTime()) {
        hasError = true
        setEndDateError("End date can not be before start date")
    } else{
        setEndDateError("")
    }
    return hasError
  }

  return (
        <div className='settings project-settings'>
          <div>
            <TextField id="outlined-basic" variant="standard" label="Project name" onChange={ (e) => onTextChange(e.target.value) } value={ projectName } autoFocus error={(descError != undefined && descError.length > 0)} helperText={descError} />
          </div>
          <div>
            <FormControl fullWidth variant="standard">
              <InputLabel id="id1">Project type</InputLabel>
              <Select native value={ projectType } onChange={ (e) => onTypeChange(e.target.value) } labelId="id1" data-testid="project-t">
                <option key='col2' value={ ROADMAP }>Quarterly Roadmap (Quarters/months)</option>
                <option key='col3' value={ WEEKLY }>Weekly Project Plan (Date based weeks)</option>
              </Select>
              { projectType === WEEKLY &&
              <div>
                <p className="label">Project start week</p>
                <input type='date' value={startDate} onChange={(e) => { setStartDate(e.target.value) }}></input>
              </div>
              }
              {projectType === WEEKLY && endDateError && <p className="error-msg">{endDateError}</p>}
              { projectType === WEEKLY &&
              <div>
                <p className="label">Project end week</p>
                <input type='date' value={endDate} min={startDate} onChange={(e) => { setEndDate(e.target.value) }} data-testid="end-date"></input>
              </div>
              }
            </FormControl>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleCreate}>Create</Button>
            </DialogActions>
          </div>
        </div>
  );
}

export default ProjectSettings