import React from 'react';
import {useSelector } from 'react-redux'
import '../App.css';
import Bar from './Bar';
import Milestone from './Milestone'
import TaskLabel from './TaskLabel';
import styled from 'styled-components';
import EditButtons from './EditButtons';
import { PROJECT, SWIMLANES } from '../actions/ConfigActions'
import { TASK, MILESTONE } from '../actions/ProjectActions'

const LabelDiv = styled.div`
  width: ${p => p.labelColWidth + 'px'};
`

const GridBarRow = ({task, start, length, cutoff, row, updatePosition, resizeTask, showEdit, openEdit, onDelete, writeName=true}) => {
  const { columns, incrementWidth, labelColWidth, increments, displayMode } = useSelector(p => p.project.timeline)
  const barStyle = useSelector(p => p.project.style)
  const columnStart = showEdit ? 3 : 2
  const maxColumns = columns * increments + (showEdit ? 2 : 1)
  const columnEnd = maxColumns + 1
  const row2 = row + 1
  const labelWidth = showEdit ? labelColWidth - 31 : labelColWidth

  return (
    <>
    { writeName && (displayMode === PROJECT) &&
    <LabelDiv style={{ gridColumn: '1 / 2', gridRow: `${row} / ${row2}`, paddingBottom: '14px' }} $showEdit={showEdit} $labelColWidth={labelWidth}>
      <TaskLabel task={task}/>
    </LabelDiv>
    }
    { writeName && showEdit &&
    <EditButtons task={task} row={row} mode={showEdit} openEdit={openEdit} onDelete={onDelete} />
    }
    { length > 0 &&
    <div style={{ gridColumn: `${columnStart + ' / ' + columnEnd}`, gridRow: `${row} / ${row2}`, paddingBottom: '14px'}}>
      <div className='slider'>
        { task.type === TASK &&
        <Bar
          key={'bar_' + task.id}
          start={start}
          width={length}
          cutoff={cutoff}
          updatePosition={updatePosition}
          resizeTask={resizeTask}
          colour={task.colour}
          borderColour={task.borderColour}
          label={displayMode === SWIMLANES ? task.name : ''}
          onClick={() => openEdit(task)}
          gridWidth={incrementWidth}
          barStyle={barStyle}
        />
        }
       { task.type === MILESTONE &&
        <Milestone
          key={'bar_' + task.id}
          start={start}
          width={length}
          cutoff={cutoff}
          updatePosition={updatePosition}
          resizeTask={resizeTask}
          colour={task.colour}
          borderColour={task.borderColour}
          label={displayMode === SWIMLANES ? task.name : ''}
          onClick={() => openEdit(task)}
          gridWidth={incrementWidth}
          shape={task.shape}
          barStyle={barStyle}
        />
        }
      </div>
    </div>
    }
    </>
  )
};

export default GridBarRow;
