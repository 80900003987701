import upgrade from './Upgrader'
import { cloneProject, upgrades as PROJECT_UPGRADES } from './ProjectReducer'

export function upgradeProjects(projectList, version, config) {
    let newList = []

    projectList.forEach((p) => {
            newList.push(
                cloneProject(
                    (!p.version || (version > p.version)) ? upgrade(p, PROJECT_UPGRADES, config) : p))
    })

    return newList
}