import {
  ADD_TASK,
  MOVE_TASK,
  MOVE_UP,
  MOVE_DOWN,
  RESIZE_TASK,
  UPDATE_TASK,
  EDIT_TASK,
  EDIT_DONE,
  DELETE_TASK,
  UPDATE_NAME,
  UPDATE_STYLE,
  UPDATE_STRICT_GANTT,
  SET_CURRENT_PROJECT,
  CREATE_PROJECT,
  TRACK_LEFT,
  TRACK_RIGHT,
  UPDATE_COLUMNS,
  UPDATE_LABEL_COL_WIDTH,
  DISPLAY_MODE,
  ROADMAP,
  ROUNDED,
  DELETE_ROW
} from '../actions/ProjectActions'
import { v4 as uuidv4 } from 'uuid'
import { UPGRADE } from '../actions/ConfigActions'
import { LATEST_VERSION } from "../Version.js"
import upgrade from './Upgrader'
import * as RoadmapTrack from '../Track'
import * as WeeklyTrack from '../WeeklyTrack'
import { convertV5Timeline } from './UpgradeHelper'

let trackSettings = RoadmapTrack.getTrackSettings()
const COLUMN_WIDTH = trackSettings.increments * trackSettings.incrementWidth
const defaultRoadmapTimeline = {
    track: RoadmapTrack.buildTrack(trackSettings.trackLength),
    index: trackSettings.index,
    columns: trackSettings.columns,
    increments: trackSettings.increments,
    justifyLeft: trackSettings.justifyLeft,
    windowLength: trackSettings.windowLength,
    trackLength: trackSettings.trackLength,
    incrementWidth: trackSettings.incrementWidth,
    labelColWidth: trackSettings.labelColumnWidth,
    displayMode: trackSettings.displayMode,
    columnWidth: COLUMN_WIDTH,
    defaultTaskLength: trackSettings.defaultTaskLength,
    maxColumns: trackSettings.maxColumns,
}

let weeklySettings = WeeklyTrack.getTrackSettings()
const WEEKLY_COLUMN_WIDTH = weeklySettings.increments * weeklySettings.incrementWidth

function buildTimeLine(startDate, endDate) {
  const minLength = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
  const track = WeeklyTrack.buildTrack(startDate, minLength)
  const maxColumns = track.length / weeklySettings.increments
  let columns = (maxColumns > weeklySettings.columns) ? weeklySettings.columns : maxColumns

  return  {
    track: track,
    index: weeklySettings.index,
    columns: columns,
    increments: weeklySettings.increments,
    justifyLeft: weeklySettings.justifyLeft,
    windowLength: columns * weeklySettings.increments,
    trackLength: track.length,
    incrementWidth: weeklySettings.incrementWidth,
    labelColWidth: weeklySettings.labelColumnWidth,
    displayMode: weeklySettings.displayMode,
    columnWidth: WEEKLY_COLUMN_WIDTH,
    defaultTaskLength: weeklySettings.defaultTaskLength,
    maxColumns: maxColumns,
}

}

const offset = new Date().getMonth() * 8

const initialState = {
  id: uuidv4(),
  version: LATEST_VERSION,
  name: 'A Demo Roadmap',
  type: ROADMAP,
  tasks: [
    {id: '1169f1e8-399d-4686-af01-8d21352ff54e', row: 0, name: 'Heading 1', type: 'heading', colour:'#3771f3', borderColour:'#2558cc'},
    {id: '73f9ad63-16ca-4ab2-8f75-4b1f62d9fdeb', row: 1, name: 'Task 1',    type: 'task', start: offset > 3 ? offset - 4 : offset, length: 16, colour:'#3771f3', borderColour:'#2558cc'},
    {id: '1169f1e8-399d-4686-af01-8d21352ff24e', row: 2, name: 'Task 2a',    type: 'task', start: offset + 8, length: 4, colour:'#ff4b4b', borderColour:'#ed3939'},
    {id: '1169f1e8-399d-4686-af01-8d21352ff24f', row: 2, name: 'Task 2b',    type: 'task', start: offset + 15, length: 12, colour:'#ff4b4b', borderColour:'#ed3939'},
    {id: '1169f1e8-399d-4686-af01-8d21352ff24g', row: 3, name: 'Milestone 1',    type: 'milestone', start: offset + 18, length: 2, colour:'#ff4b4b', borderColour:'#ed3939'},
    {id: '1169f1e8-399d-4686-af01-8d21352ff24f', row: 4, name: 'Heading 2', type: 'heading', colour:'#3771f3', borderColour:'#2558cc'},
    {id: '4c49181d-4c37-4e5a-9b4e-f64fcea28bca', row: 5, name: 'Task 3',    type: 'task', start: offset + 4, length: 4, colour:'#3771f3', borderColour:'#2558cc'},
    {id: '4c49181d-4c37-4e5a-9b4e-f64fcea2acdd', row: 6, name: 'Task 4',    type: 'task', start: offset + 12, length: 24, colour:'#43c504', borderColour:'#31b300'},
    {id: '4c49181d-4c37-4e5a-9b4e-f64fcea28bcb', row: 7, name: 'Task 5',    type: 'task', start: offset + 4, length: 64, colour:'#fbbc10', borderColour:'#dfaa00'}
  ],
  numRows: 8,
  editTask: {},
  timeline: defaultRoadmapTimeline,
  style: ROUNDED,
  strictGantt: true,
  lastUpdated: new Date().getTime(),
}

const upgrades = [
  {
    version: 5,
    state: {},
    action: (state, config) => {
        return {
          ...state,
          id: uuidv4()
        }
      }
  },
  {
    version: 6,
    state: {},
    action: (state, config) => {
      return {
        ...state,
        timeline: convertV5Timeline(config),
        type: ROADMAP,
      }
    }
  },
  {
    version: 7,
    state: {
      lastUpdated: new Date().getTime(),
    }
  },
  {
    version: 8,
    state: {},
    action: (state, config) => {
        const updatedTasks = []
        state.tasks.forEach((t, i) => {
            updatedTasks.push(Object.assign({}, t, {row: i}))
        })
        return {
          ...state,
          tasks: updatedTasks,
          numRows: updatedTasks.length,
          style: ROUNDED,
        }
      }
  },
]


const project = function (state = initialState, action) {
    switch (action.type) {
      case TRACK_LEFT:
        const leftShiftedTimeline = {
          ...state.timeline,
          index: (state.timeline.index > state.timeline.increments) ? state.timeline.index - state.timeline.increments : 0,
        }
        return {
          ...state,
          timeline: leftShiftedTimeline,
          lastUpdated: new Date().getTime()
        }
      case TRACK_RIGHT:
        const rightShiftedTimeline = {
          ...state.timeline,
          index: (state.timeline.trackLength - state.timeline.index - state.timeline.windowLength > 0) ? state.timeline.index + state.timeline.increments : state.timeline.index
        }
        return {
          ...state,
          timeline: rightShiftedTimeline,
          lastUpdated: new Date().getTime()
        }
      case UPDATE_COLUMNS: {
        if (action.columns > state.timeline.maxColumns) {
            return state
        }
        const maxIndex = state.timeline.trackLength - (action.columns * state.timeline.increments)
        const updatedTimeline = {
          ...state.timeline,
          columns: action.columns,
          windowLength: action.columns * state.timeline.increments,
          index: state.timeline.index > maxIndex ? maxIndex : state.timeline.index,
        }
        return {
          ...state,
          timeline: updatedTimeline,
          lastUpdated: new Date().getTime()
        }
      }
      case UPDATE_LABEL_COL_WIDTH: {
        const updatedTimeline = {
          ...state.timeline,
          labelColWidth: action.width,
        }
        return {
          ...state,
          timeline: updatedTimeline,
          lastUpdated: new Date().getTime()
        }
      }
      case DISPLAY_MODE: {
        const updatedTimeline = {
          ...state.timeline,
          displayMode: action.mode
        }
        return {
          ...state,
          timeline: updatedTimeline,
          lastUpdated: new Date().getTime()
        }
      }
      case UPGRADE: {
        if (!state.version || (initialState.version > state.version)) {
          return upgrade(state, upgrades, action.config)
        }
        return state
      }
      case ADD_TASK:
        const newTask = Object.assign({}, action.task)
        if (!action.task.length || action.task.length < 1) {
            newTask.length = 1
        }

        if (newTask.start + newTask.length > state.timeline.trackLength) {
            return state
        }

        return {
            ...state,
            tasks: [...state.tasks, newTask],
            numRows: (action.task.row >= state.numRows) ? action.task.row + 1 : state.numRows,
            editTask: newTask,
            lastUpdated: new Date().getTime()
        }
      case MOVE_TASK: {
        const i = state.tasks.findIndex(t => t.id === action.task.id)
        const newTask = Object.assign({}, state.tasks[i], {start: action.start})

        if (newTask.start + newTask.length > state.timeline.trackLength) {
            return state
        }

        return {
            ...state,
            tasks: Object.assign([...state.tasks], {[i]: newTask}),
            lastUpdated: new Date().getTime()
        }
      }
      case MOVE_UP: {
        const targetRow = (action.task.row - action.by < 0) ? 0 : action.task.row - action.by
        const newTasks = []
        state.tasks.forEach((t) => {
            const newRow = t.row === action.task.row ? targetRow : (t.row >= targetRow && t.row < action.task.row ? t.row + 1 : t.row)
            newTasks.push(Object.assign({}, t, {row: newRow}))
        })

        return {
            ...state,
            tasks: newTasks,
            lastUpdated: new Date().getTime()
        }
      }
      case MOVE_DOWN: {
        const targetRow = (action.task.row + action.by < state.numRows) ? action.task.row + action.by : state.numRows - 1
        const newTasks = []
        state.tasks.forEach((t) => {
            const newRow = t.row === action.task.row ? targetRow : (t.row <= targetRow && t.row > action.task.row ? t.row - 1 : t.row)
            newTasks.push(Object.assign({}, t, {row: newRow}))
        })

        return {
            ...state,
            tasks: newTasks,
            lastUpdated: new Date().getTime()
        }
      }
      case UPDATE_TASK: {
        const i = state.tasks.findIndex(t => t.id === action.taskId)
        const updatedTask = Object.assign({}, state.tasks[i], action.changes, {lastUpdated: new Date().getTime()})

        if (updatedTask.length < 1) {
          return state
        }

        return {
            ...state,
            tasks: Object.assign([...state.tasks], {[i]: updatedTask}),
            editTask: updatedTask,
            lastUpdated: new Date().getTime()
        }
      }

      /**
       * If resizing from the front there will also be a move action.
       */
      case RESIZE_TASK: {
        const i = state.tasks.findIndex(t => t.id === action.task.id)
        const newTask = Object.assign({}, state.tasks[i], {length: action.length})

        return {
            ...state,
            tasks: Object.assign([...state.tasks], {[i]: newTask}),
            lastUpdated: new Date().getTime()
        }

      }
      case EDIT_TASK: {
        const i = state.tasks.findIndex(t => t.id === action.task.id)

        return {
            ...state,
            editTask: state.tasks[i]
        }
      }
      case EDIT_DONE: {
        return {
            ...state,
            editTask: {},
        }
      }
      case DELETE_TASK: {
        const deleteIndex = state.tasks.findIndex(t => t.id === action.task.id)

        if (deleteIndex < 0) {
          return state
        }

        const tasks = [...state.tasks.slice(0, deleteIndex), ...state.tasks.slice(deleteIndex + 1)]
        const rowDeleted = tasks.find(t => t.row === action.task.row) === undefined
        if (rowDeleted) {
            for (let i = 0; i < tasks.length; i++) {
                if (tasks[i].row > action.task.row) {
                    tasks[i] = Object.assign({}, tasks[i], {row: tasks[i].row - 1})
                }
            }
        }

        return {
            ...state,
            tasks: tasks,
            numRows: rowDeleted ? state.numRows - 1 : state.numRows,
            lastUpdated: new Date().getTime()
        }
      }
      case DELETE_ROW: {
        const remainingTasks = []

        for (let i = 0; i < state.tasks.length; i++) {
            if (state.tasks[i].row < action.rowIndex) {
                remainingTasks.push(Object.assign({}, state.tasks[i]))
            } else if (state.tasks[i].row > action.rowIndex) {
                remainingTasks.push(Object.assign({}, state.tasks[i], {row: state.tasks[i].row - 1}))
            }
        }
        const rowDeleted = remainingTasks.length !== state.tasks.length

        return {
            ...state,
            tasks: remainingTasks,
            numRows: rowDeleted ? state.numRows - 1 : state.numRows,
            lastUpdated: new Date().getTime()
        }
      }
      case UPDATE_NAME: {
        return {
          ...state,
          name: action.name,
          lastUpdated: new Date().getTime()
        }
      }
      case UPDATE_STYLE: {
        return {
          ...state,
          style: action.style,
          lastUpdated: new Date().getTime()
        }
      }
      case UPDATE_STRICT_GANTT: {
        return {
          ...state,
          strictGantt: action.strictGantt,
          lastUpdated: new Date().getTime()
        }
      }
      case SET_CURRENT_PROJECT: {
        return {
          ...state,
          ...cloneProject(action.project),
        }
      }
      case CREATE_PROJECT: {
        const newProject = action.project
        newProject.timeline =
            action.project.type === 'ROADMAP' ? defaultRoadmapTimeline : buildTimeLine(newProject.startDate, newProject.endDate)

        return {
          ...state,
          ...cloneProject(newProject),
          lastUpdated: new Date().getTime()
        }
      }

      default:
        return state;
    }
  }

  function cloneProject(project) {
    return {
      ...project,
      tasks: [...project.tasks],
      timeline: {...project.timeline}
    }
  }

export { project, cloneProject, upgrades, defaultRoadmapTimeline }