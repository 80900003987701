/**
 * Quarterly Track
 * row[0] is quarter
 * row[1] is month
 * row[2] is ?
 * row[3] is ?
 */

const row3 = [
    {label: 'Q1', name: 'Q1', length: 24, row: 0},
    {label: 'Q2', name: 'Q2', length: 24, row: 0},
    {label: 'Q3', name: 'Q3', length: 24, row: 0},
    {label: 'Q4', name: 'Q4', length: 24, row: 0},
]

const row2 = [
    {label: 'Jan', name: 'January', length: 8, row: 1},
    {label: 'Feb', name: 'February', length: 8, row: 1},
    {label: 'Mar', name: 'March', length: 8, row: 1},
    {label: 'Apr', name: 'April', length: 8, row: 1},
    {label: 'May', name: 'May', length: 8, row: 1},
    {label: 'Jun', name: 'June', length: 8, row: 1},
    {label: 'Jul', name: 'July', length: 8, row: 1},
    {label: 'Aug', name: 'August', length: 8, row: 1},
    {label: 'Sep', name: 'September', length: 8, row: 1},
    {label: 'Oct', name: 'October', length: 8, row: 1},
    {label: 'Nov', name: 'November', length: 8, row: 1},
    {label: 'Dec', name: 'December', length: 8, row: 1}
]

const QUARTERLY_TRACK = {
    columns: 6,
    labelColumnWidth: 220,
    increments: 8,
    incrementWidth: 16,
    windowLength: 48,
    trackLength: 192,
    index: new Date().getMonth() * 8,
    displayMode: 'PROJECT',
    defaultTaskLength: 8,
    maxColumns: 12,
}

export function getTrackSettings() { return QUARTERLY_TRACK }

export function buildTrack(size=96) {
    let row2Index = 0;
    let row3Index = 0;
    let track = []

    track.push([row2[row2Index++], row3[row3Index++]])
    for (let i = 1; i < size; i++) {
        let headings = []
        if (i % row2[row2Index].length === 0) {
            headings.push(row2[row2Index])
            row2Index++
            if (row2Index >= row2.length) row2Index = 0
        }
        if (i % row3[row3Index].length === 0) {
            headings.push(row3[row3Index])
            row3Index++
            if (row3Index >= row3.length) row3Index = 0
        }
        track.push(headings)
    }

    return track
}

